<template>
    <v-container fluid class="fill-height text-left pa-0">
        <v-row>
            <v-col>
                <v-card class="fill-height container--fluid" width="100vw" flat tile>
                    <v-row justify="start">
                        <v-col cols="12">
                            <v-card class="d-flex fill-height d-flex fluid pa-0 ma-0" flat tile width="100vw">
                                <v-scroll-x-transition appear>
                                    <v-img
                                        src="@/assets/images/bg-breadcrumbs.jpg"
                                        transition="slide-x-transition"
                                        position="center"
                                        width="100%"
                                        height="200"
                                        max-height="200">
                                        <v-container
                                            fluid
                                            class="fill-height align-end">
                                            <v-row align="end" justify="start">
                                                <v-col cols="12">
                                                    <div class="white--text">
                                                        <span class="white--text pl-5 text-h4 text-md-h3 font-weight-black">
                                                            Únete a BHC...
                                                        </span>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-breadcrumbs dark :items="items">
                                                        <template v-slot:divider>
                                                            <v-icon>mdi-arrow-right</v-icon>
                                                        </template>
                                                        <template v-slot:item="{ item }">
                                                            <v-breadcrumbs-item
                                                                :href="item.href"
                                                                :disabled="item.disabled">
                                                        <span class="white--text">
                                                            {{ item.text }}
                                                        </span>
                                                            </v-breadcrumbs-item>
                                                        </template>
                                                    </v-breadcrumbs>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-img>
                                </v-scroll-x-transition>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="align-center justify-start">
                            <v-card class="ma-0 ma-md-5" flat tile>
                                <v-container fluid class="mx-0 mt-1">
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-container fluid class="text-left">
                                                <v-row justify="start">
                                                    <v-col cols="12">
                                                        <span class="text-h4 font-weight-bold headline text-uppercase">
                                                            Únete al BHC
                                                        </span>
                                                        <div class="boxdivider"></div>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-responsive class="pt-1" :max-width="$vuetify.breakpoint.xsOnly ? 400 : '100%'">
                                                        <span class="text-body-1 font-weight-light grey--text text--darken-3">
                                                            <p>
                                                                Si eres médico certificado, dentista, o representante de un hospital, clínica,
                                                                empresa perteneciente al Sector de Turismo de Salud en Baja California y te
                                                                interesa pertenecer al Turismo BC, por favor llena el siguiente formulario.
                                                            </p>
                                                            <p>
                                                                Nos pondremos en contacto contigo a la brevedad posible.
                                                            </p>
                                                        </span>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <div class="v-card rounded-lg elevation-5">
                                                            <v-img
                                                                src="@/assets/images/jointo-bhc.jpg"
                                                                transition="slide-x-transition"
                                                                position="top"
                                                                width="100%"
                                                                max-height="450">
                                                            </v-img>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-card class="ma-0" elevation="0" rounded>
                                                <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                                                    <v-container class="mx-0 mt-0">
                                                        <v-row dense>
                                                            <v-col cols="12">
                                                                <v-card-title class="text-h5 pl-1 blue--text">
                                                                    <span class="headline">Información de contactos</span>
                                                                </v-card-title>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    dense
                                                                    v-model="editItem.name"
                                                                    label="Nombre y Apellido"
                                                                    rounded
                                                                    filled
                                                                    :rules="textRules"
                                                                    required>
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-text-field-simplemask
                                                                    v-model="editItem.phone"
                                                                    label="Teléfono"
                                                                    v-bind:properties="{
                                                                    dense: true,
                                                                    prefix: '',
                                                                    suffix: '',
                                                                    readonly: false,
                                                                    disabled: false,
                                                                    outlined: false,
                                                                    rounded: true,
                                                                    filled: true,
                                                                    placeholder: '',
                                                                    required: true,
                                                                    rules: textRules
                                                                }"
                                                                    v-bind:options="{
                                                            inputMask: '(###) ###-####',
                                                            outputMask: '##########',
                                                            empty: null,
                                                            applyAfter: false,
                                                            alphanumeric: false,
                                                            lowerCase: false,
                                                        }"
                                                                    v-bind:focus="focus"
                                                                    v-on:focus="focus = false"
                                                                />
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    dense
                                                                    v-model="editItem.email"
                                                                    :rules="emailRules"
                                                                    label="Correo electrónico"
                                                                    rounded
                                                                    filled
                                                                    required
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    dense
                                                                    v-model="editItem.company"
                                                                    label="Hospital o Empresa"
                                                                    rounded
                                                                    filled
                                                                    hint="Hospital o empresa a la que representa (si aplica)"
                                                                    persistent-hint
                                                                ></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" v-show="false">
                                                                <v-textarea
                                                                    dense
                                                                    v-model="editItem.comment"
                                                                    label="Comentario"
                                                                    no-resize
                                                                    rounded
                                                                    filled
                                                                    rows="6"
                                                                    counter
                                                                    required>
                                                                </v-textarea>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                    <v-card-actions class="mt-0 pb-5 pl-5">
                                                        <v-btn :disabled="!valid" color="primary" rounded large @click="saveData">
                                                            Enviar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-form>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn  v-bind="attrs"
                        icon
                        @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import joinToService from '@/providers/JoinToService';
    
    export default {
        name: "JoinToComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            editItem : {
                name: '',
                phone: '',
                email: '',
                company: '',
                comment: ''
            },
            defaultEditItem : {
                name: '',
                phone: '',
                email: '',
                company: '',
                comment: ''
            },
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido"
            ],
            items: [
                {
                    text: 'Home',
                    disabled: false,
                    href: '/homepage',
                },
                {
                    text: 'Únete al BHC',
                    disabled: true,
                    href: '/jointobhc',
                }
            ],
        }),
        methods: {
            saveData() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // Agrega el registro por el metodo POST
                    joinToService.addRecord(this.editItem).then(result => {
                        if (result.success) {
                            this.editItem = Object.assign({}, this.defaultEditItem);
                            this.snackText = 'Datos de solicitud guardado con éxito...';
                        } else {
                            this.snackText = 'Un error impidió guardar los datos requeridos!';
                        }
                        this.snackBar = true;
                        this.$refs.form.reset();
                    });
                }
            },
        },
        mounted() {
            this.editItem = Object.assign({}, this.defaultEditItem);
        },
    }
</script>

<style scoped>
    .bottom-gradient {
        background-image: linear-gradient(
                to bottom,
                rgba(163, 153, 248, 0.295) 0%,
                rgba(101, 99, 248, 0.699) 100%
        );
    }
    .blend-opacity {
        opacity: 0.1;
        transition: opacity 0.4s ease-in-out;
        background-color: black;
        background-blend-mode: normal;
    }
    .boxdivider {
        background-color: transparent;
        width: 100px;
        height: 20px;
        color: #ffffff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-bottom-width: 3px;
        border-bottom-color: blueviolet;
        border-bottom-style: solid;
        display: block;
    }
</style>