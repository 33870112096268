<template>
    <div>
        <join-to-component/>
        <footer-component/>
    </div>
</template>

<script>
    import JoinToComponent from "@/components/join-bhc/JoinToComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "JoinToBHC",
        title: "Únete a Turismo BC",
        components: {
            JoinToComponent, FooterComponent
        }
    }
</script>

<style scoped>

</style>